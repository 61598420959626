<template>
    <div class="wrapper">
        <div class="button" v-show="!isopen" @click="clicks"></div>
        <div class="videoBox" v-show="isopen&&zindex == 5">
            <video-player class=" video-player vjs-custom-skin"
            id="video"
　　　　　　ref="videoPlayer"
　　　　　　:playsinline="true"
　　　　　　:options="playerOptions"
            @ready="onPlayerReady"
            @ended="onPlayerEnded"
　　　　　　></video-player>
        </div>
        <div class="bg" v-show="zindex == 3">
            <img :src="imageUrl" alt="" @click="getEvent()">
        </div>
        <div class="dialogue" v-show="isend&&eventId == '75'">
            <div :class="(selectIndex == index&&item.roleType != 1) ?'sz' :'select'"  v-for="(item,index) in selectList" :key="index" @click="select(item,index)">
                <img :src="item.imageUrl" alt="" v-show="item.roleType == 3">
                <img :src="item.imageUrl" alt="" class="images" v-show="item.roleType == 1">

            </div>
        </div>
        <div class="dialogue2" v-show="isend&&eventId != '75'">
            <div :class="(selectIndex == index&&item.roleType != 1) ?'sz' :'select'"  v-for="(item,index) in selectList" :key="index" @click="select(item,index)">
                <img :src="item.imageUrl" alt="" v-show="item.roleType == 3">
                <img :src="item.imageUrl" alt="" class="images" v-show="item.roleType == 1">

            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { videoPlayer } from 'vue-video-player'
  import 'video.js/dist/video-js.css'
  import 'vue-video-player/src/custom-theme.css'
  import { getSessionNew,getEvent,getEcho } from '@/api'
  import popup from '@/components/popup.vue'
  export default {
    components: { 
        popup,
        videoPlayer
     },
    props: {},
    data() {
      return {
        text:'',
        sessionId:'',
        time2:null,
        isshow:false,
        popupData:[],
        ispopupData:false,
        selectIndex:-1,
        isclick:false,
        list:{},
        names:'http://192.168.42.43:1234',
        newList:{},
        isIos:false,
        fileType:'mp4',
        url:'',
        isopen:false,
        isend:false,
        selectList:[],
        imageUrl:'',
        zindex:0,
        eventId:''
      }
    },
    watch: {},
    computed: {
        play(){
            return this.$refs.videoPlayer.player
        },
    　　playerOptions () {
    　　　　const playerOptionsObj = {
               muted:true,
    　　　　　　playbackRates: [0.7, 1.0, 1.5, 2.0], //视频播放速度
    　　　　　　autoplay: true, // 如果true，浏览器准备好时开始回放。
    　　　　　　muted: false, // 默认情况下将会消除任何音频。
    　　　　　　loop: false, // 导致视频一结束就重新开始。
    　　　　　　// preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）。
    　　　　　　language: 'zh-CN',
    　　　　　　// aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）。
    　　　　　　fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
    　　　　　　sources: [{
    　　　　　　　　type: 'video/' + this.fileType, // 资源格式写法：'video/mp4'，否则控制台会出现notSupportedMessage设置的错误。
    　　　　　　　　src: this.url // 视频url地址
    　　　　　　}],
        　　　　poster: '', // 视频封面地址
        　　　　width: '100%',
        　　　　height: '100%', // 设置高度，fluid需要设置成flase
        　　　　notSupportedMessage: '此视频暂无法播放...', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        　　　　controlBar: {
        　　　　　　timeDivider: true,
        　　　　　　durationDisplay: true,
        　　　　　　remainingTimeDisplay: false,
        　　　　　　fullscreenToggle: true //全屏按钮
        　　　　}
        　　}
    　　    return playerOptionsObj
    　　}
    },
    methods: {
        onPlayerReady(){
            this.aaa()
            console.log('准备好了')

        },
        jump(url){
            location.href = url

        },
        getVideoCover(url) {
            let that = this
            return new Promise(function (resolve, reject) {
                let dataURL = '';
                let video = document.createElement("video");
                video.setAttribute('crossOrigin', 'anonymous');//处理跨域
                video.setAttribute('src', url);
                video.setAttribute('width', 300);
                video.setAttribute('height', 450);
                video.currentTime = 0.1
                video.addEventListener('loadeddata', function () {
                    let canvas = document.createElement("canvas"),
                        width = video.width, //canvas的尺寸和图片一样
                        height = video.height;
                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext("2d").drawImage(video, 0, 0, width, height); //绘制canvas
                    dataURL = canvas.toDataURL('image/jpeg'); //转换为base64
                    resolve(dataURL);
                });
            })
        },
        video(item){
            this.$refs.popup.url = item.imageUrl
            this.$refs.popup.show = true
            this.$refs.popup.play()

        },
        reveal(){
            let arr = this.cutString(this.list.text, 1);
            // this.newList.push({
            //     type:this.list[this.listNUm].type,
            //     text:''
            // })
            let num = 0
            this.time = setInterval(()=>{
                this.text = this.text + arr[num]
                
                // let middle= this.$refs["middle"];
                // middle.scrollTop = middle.scrollHeight;
                num++
                if(num == arr.length){
                    // if(this.listNUm<this.list.length){
                    //     setTimeout(()=>{
                    //         this.reveal()
                    //     },500)
                    // }
                    this.isshow = true
                    // setTimeout(()=>{
                    //     this.getEvent()
                    // },500)
                    clearInterval(this.time)
                }
            },20)

            
        },
        cutString(str, len) {
            var result = [];
            for (var i = 0; i < str.length; i += len) {
                result.push(str.substring(i, i + len));
            }
            return result;
        },
        getSessionNew(){
            let param = {
                storyId:0,
                sceneId:0,
                eventId:0,
                ...sessionStorage.getItem('obj')?JSON.parse(sessionStorage.getItem('obj')):{}
            }
            getSessionNew(param).then(res=>{
                this.sessionId = res.data.data.sessionId
                this.polling()
            })
        },
        polling(){
            // this.time2 = setInterval(()=>{
                this.getEvent()
            // },1000)
        },
        click(){


        //             setTimeout(()=>{
        //     console.log(this.play,'yinp')
        //     this.play.play()

        // },1000)
            this.getEvent()

        },
        select(item,index){
            if(this.isclick){
                return
            }
            this.isclick = true
            if(item.displayType == 6){
                window.location.href = item.link

            }else{
                
                this.selectIndex = index
                this.getEcho(item)

            }


        },
        getEcho(item){
            let data = item
            
                // console.log('csrrrss')

                // this.cs()
            getEcho({
                sessionId:this.sessionId,
                eventId:data.id
            }).then(res=>{
                // this.popupData = []
                setTimeout(()=>{
                    this.isend = false
                    this.isclick = false
                    this.selectIndex = -1
                    this.polling()
                },500)


            })

        },
        cs(){

        },
        addBg(data){
            
            this.text = ''
            this.list = data.eventList[0]
            //this.list.imageUrl = this.names + this.list.imageUrl
            this.list.imageUrl = this.list.imageUrl
            // if(data.eventList[0].displayType == 5){
            //     // let res = this.getVideoCover(this.list.imageUrl)
            //     let res = await this.getVideoCover(this.list.imageUrl)
            //     this.list.bgImg = res
                
            // }
            this.newList =  this.list

            this.reveal()
            console.log(data.eventList[0].displayType)
            if(data.eventList[0].displayType == 5){
                const u = navigator.userAgent;
                const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
                console.log(isiOS,'是否苹果')
                if (isiOS) {
                    this.isIos = true
                    console.log('苹果')
                    
                    
                    
                }else{
                    console.log('安卓')
                    
                    this.$refs.popup.url = this.newList.imageUrl
                    this.$refs.popup.show = true 
                    this.$refs.popup.play()

                }


                // this.$refs.popup.url = this.newList.imageUrl
                // this.$refs.popup.show = true
                // this.$refs.popup.play()
                setTimeout(()=>{
                    this.getEvent()
                },2000)
            }

        },
        aaa(){
                console.log(this.play,'yinp')
                this.play.play()

        },
        onPlayerEnded(){
            console.log('结束')
            setTimeout(()=>{
                this.getEvent()

            },200)

        },
        getEvent(){
            
            getEvent({
                sessionId:this.sessionId,
                eventId:0
            }).then(res=>{
                
                let data = res.data.data
                this.list = data.eventList[0]
                this.eventId = data.eventId
                console.log('list',this.list)
                if(data.eventType == 1){
                    if(data.eventList[0].displayType==5){
                        this.zindex = 5
                        this.url = data.eventList[0].imageUrl
                        console.log('sss',this.url)
                        setTimeout(()=>{
                            this.play.play()

                        },100)
                        

                    }else if(data.eventList[0].displayType==3){
                        this.zindex = 3
                        this.imageUrl = data.eventList[0].imageUrl
                        this.getEvent()

                    }else if(data.eventList[0].displayType == 6){
                        window.location.href = data.eventList[0].link

                    }


                }else{
                    this.selectList = data.eventList
                    this.isend = true

                }

                return
                if(data.eventType == 1){
                    
                    
                    if(data.eventList[0].displayType == 1||data.eventList[0].displayType == 5||data.eventList[0].displayType == 6){
                       
                        
                        
                                    
                        this.text = ''
                        this.list = data.eventList[0]
                        //this.list.imageUrl = this.names + this.list.imageUrl
                        // this.list.imageUrl = this.list.imageUrl
                        // if(data.eventList[0].displayType == 5){
                        //     // let res = this.getVideoCover(this.list.imageUrl)
                        //     let res = await this.getVideoCover(this.list.imageUrl)
                        //     this.list.bgImg = res
                            
                        // }
                        this.newList =  this.list
                        
                        

                        // this.reveal()

                            // this.$refs.popup.url = this.newList.imageUrl
                            // this.$refs.popup.show = true
                            // this.$refs.popup.play()
                            // setTimeout(()=>{
                            //     this.getEvent()
                            // },2000)
                        // this.addBg(data)

                    }
                    //对话
                    // this.list.push({
                    //     type:data.eventList[0].roleType,
                    //     text:data.eventList[0].text
                    // })

                    // this.reveal()
                    // if(data.eventList[0].roleType == 1){
                    //     clearInterval(this.time2)
                    //     this.time2 = null
                    //     return
                    // }
                }else if(data.eventType == 2){
                    // clearInterval(this.time2)
                    // this.time2 = null
                    this.popupData = data.eventList
                    
                    this.ispopupData = true
                    //选项
                    // this.$refs.popup.show = true
                }else{
                    this.ispopupData = true
                    // if(this.time2 == null){
                    //     this.polling()
                    // }
                    // console.log(this.time2,'322222222222')
                    // setTimeout(()=>{
                    //     this.getEvent()

                    // },500)
                    

                }

            })

        },
        clicks(){
            this.isopen = true
            this.getSessionNew()

        },

  
    },
    created() { 
        // let url = window.location.href
        // console.log(this.getUrlParams(url))
    
    
     },
    mounted() {
        // setTimeout(()=>{
        //     console.log(this.play,'yinp')
        //     this.play.play()

        // },1000)
     }
  }
  </script>
  <style scoped lang="scss">
  /deep/ .vjs-big-play-button{
    opacity: 0 !important;
  }
  .bg{
    width: 100vw;
    height: 100vh;

    img{
        width: 100%;
        height: 100%;
    }
  }
    .button{
        //homeBt
        width: 100vw;
        height: 1.9rem;
        color: #fff;
        text-align: center;
        background-image: url(~@/assets/images/homeBt.png);
        background-size: 100% auto;
        background-position: top;
        background-repeat: no-repeat;
        position: absolute;
        bottom: 1.5rem;
        animation: likes .8s ease 0s infinite alternate;
    }

    @keyframes likes {
        0% {
            transform: scale(.9);
        }

        100% {
            transform: scale(1);
        }
    }
  /deep/.video-js{
    height: 100vh !important;
  }
  .video-player{
    height: 100vh !important;
  }
 /deep/ .vjs_video_750-dimensions{
    height: 100vh !important;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-image: url(~@/assets/images/homeBg.jpg);
    background-size: 100% auto;
    background-position: top;
    background-repeat: no-repeat;
    background-color: #000;
    .videoBox{
        width: 100vw;
        height: 100vh;

    }

 
  }
  .dialogue{

    width: 100vw;
    height: 100vh;
    background-color: transparent;
    position: absolute;
    border-radius: .3rem;
    display: flex;
    // flex-direction: column;
    // align-items: center;
    justify-content: center;
    

    img{
        width: 100%;
        height: 100%;
    }
    .images{
        width: 100vw;
        height: 1.9rem;
        position: fixed;
        bottom: 1.5rem;
        left: 0;
        animation: likes .8s ease 0s infinite alternate;
    }

    @keyframes likes {
        0% {
            transform: scale(.9);
        }

        100% {
            transform: scale(1);
        }
    }
    .select{
        width: 3.4rem;
        height: 1.27rem;
        margin-top: 9rem;
        margin-right: .2rem;
    }
    .sz{
        width: 3.4rem;
        height: 1.27rem;
        margin-top: 9rem;
        margin-right: .2rem;
        transform: scale(1.05);

    }

  }
  .dialogue2{

    width: 100vw;
    height: 100vh;
    background-color: transparent;
    position: absolute;
    border-radius: .3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    img{
        width: 100%;
        height: 100%;
    }
    .images{
        width: 100vw;
        height: 1.9rem;
        position: fixed;
        bottom: 1.5rem;
        left: 0;
        animation: likes .8s ease 0s infinite alternate;
    }

    @keyframes likes {
        0% {
            transform: scale(.9);
        }

        100% {
            transform: scale(1);
        }
    }
    .select{
        width: 3.4rem;
        height: 1.27rem;
        margin-top: .1rem;
    }
    .sz{
        width: 3.4rem;
        height: 1.27rem;
        margin-top: .1rem;
        transform: scale(1.05);

    }

}
  </style>